// 基本情報用
export const basicInformationData = {
  productName: {
    value: '',
  },
  insurancePeriodText: {
    value: '',
  },
  earthquakeInsurancePeriodText: {
    value: '',
  },
};

// 保険金額用
export const insuranceAmountData = {
  buildingBasicPrice: {
    typeTitle: 'building',
    type: 'building', // 表の左端カテゴリ1列目の項目名(表の左端カテゴリが2列の場合に設定)
    value: '', // 表の左端カテゴリ1列目の rowspan 数に応じて設定する
  },
  addOnText: {
    type: 'building',
    value: '',
  },
  buildingEarthquakePrice: {
    type: 'building',
    value: '',
  },
  houseItemBasicPrice: {
    typeTitle: 'contents',
    type: 'contents',
    value: '',
  },
  houseItemEarthquakePrice: {
    type: 'contents',
    value: '',
  },
  houseItemNonPreciousMetalBasicPrice: {
    type: 'contents',
    value: '',
  },
  houseItemPreciousMetalBasicPrice: {
    type: 'contents',
    value: '',
  },
};

// 損害保険金用
export const damageInsuranceAmountData = [];

// 地震保険
export const earthquakeInsuranceData = {
  isBuildingEnabled: {
    typeTitle: 'earthquakeInsurance',
    type: 'earthquakeInsurance', // 表の左端カテゴリ1列目の項目名(表の左端カテゴリが2列の場合に設定)
    value: '', // 表の左端カテゴリ1列目の rowspan 数に応じて設定する
  },
  isHouseItemEnabled: {
    type: 'earthquakeInsurance',
    value: '',
  },
};

// 費用保険金用
export const costBurdenAmountData = {
  earthquakeFirePrice: {
    value: '',
  },
  waterPipeRepairPrice: {
    value: '',
  },
  contingencyPrice: {
    value: '',
  },
};

// 特約用
export const collateralPlanOptionData = [];

// 自動セットされる特約用
export const autoSetPlanOptionData = {
  autoSetPlanOption: {
    value: '',
  },
};

// その他の特約用
export const anotherPlanOptionData = {
  anotherPlanOption: {
    value: '',
  },
};

// 割増引用
export const discountRateData = {
  discountRate: {
    value: '',
  },
};

// 払込方法用
export const paymentMethodData = {
  paymentTypeName: {
    value: '',
  },
  transferTypeName: {
    value: '',
  },
  financialAccount: {
    value: '',
  },
  newRegistration: {
    value: '',
  },
  createMethod: {
    value: '',
  },
  notificationMethod: {
    value: '',
  },
  paymentConvenienceStore: {
    value: '',
  },
};
